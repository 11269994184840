import { ActionFunction, json, redirect } from '@remix-run/node';
import { Form, useActionData } from '@remix-run/react';
import { useLocation } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail.js';
import AuthPanel from '~/components/AuthPanel';
import { usePageTitle } from '~/services/hooks/usePageTitle';
import ErrorAlert from '~/components/ErrorAlert';
import { getParams } from '~/components/paramUtils';
import AuthProviders from '~/components/AuthProviders';
import * as authProviderUtils from '~/components/AuthProviders/utils';
import { CityContext } from '~/services/Contexts';
import { validateUser } from '~/utils/cnapi2';
import { getQueryParamString, getSubdomain } from '~/utils/web';
import { Input, Label, Row } from 'reactstrap';
import { Button } from '~/components/ui/button';
import "~/styles/global-styles.css?__remix_sideEffect__";

export const action: ActionFunction = async ({ request }) => {
  const url = new URL(request.url);
  const redirectUrl = url.searchParams.get('redirectTo');
  const formData = await request.formData();
  const email = (formData.get('login-email') as string);
  const inviteCode = (formData.get('inviteCode') as string);
  const emailIsValidFormat = isEmail(email);
  if (emailIsValidFormat) {
    try {
      const subdomain = getSubdomain(request);
      const { exists } = await validateUser(subdomain, email);
      const inviteCodeStr = getQueryParamString('inviteCode', inviteCode);
      const emailStr = getQueryParamString('email', email);
      if (exists) {
        const passwordUrl = `/cognitive/auth/login-password?${emailStr}${
        inviteCodeStr ? `&${inviteCodeStr}` : ''
        }${redirectUrl ? `&redirectTo=${redirectUrl}` : ''}`;
        return redirect(passwordUrl);
      }
      const path = '/cognitive/auth/sign-up';
      const search = `?email=${encodeURIComponent(email)}${
      inviteCodeStr ? `&${inviteCodeStr}` : ''
      }`;
      return redirect(`${path}/${search}`);
    } catch (err: any) {
      if (err.message === 'WhitelistError') {
        return json({
          errors: {
            whiteListError: true
          }
        });
      }
      return json({
        errors: {
          unknownError: true
        }
      });
    }
  } else {
    return json({
      errors: {
        emailValidationError: true
      }
    });
  }
};

interface Props {
  hasExaptiveProvider: boolean;
  hasNonExaptiveProvider: boolean;
  nonExaptiveProviders: any[];
}

function Landing({
  hasExaptiveProvider,
  hasNonExaptiveProvider,
  nonExaptiveProviders
}: Props) {
  const actionData = useActionData<any>();
  const location = useLocation();
  const { email, inviteCode, autoLogin } = getParams(location.search);
  usePageTitle('Welcome');

  return (
    <>
      <AuthPanel
        className="login-panel"
        title="Welcome"
        subTitle={
        hasExaptiveProvider ? 'Enter your email address to continue' : ''}>


        {hasExaptiveProvider &&
        <Form method="post" action="/cognitive/auth/landing">
            <Row>
              <Label htmlFor="login-email">Email</Label>
              <Input
              type="email"
              name="login-email"
              id="login-email"
              tabIndex={1}
              value={(email as string)}
              autoFocus />

              <input
              type="hidden"
              name="inviteCode"
              value={(inviteCode as string)} />

            </Row>
            {actionData?.errors?.emailValidationError &&
          <Row className="text-sm text-danger">
                Enter a valid email address
              </Row>}

            {actionData?.errors?.unknownError &&
          <ErrorAlert errorMsg={'Something went wrong'} />}

            <Button
            className="mt-6 flex w-full items-center justify-center text-white"
            type="submit"
            size="default">

              Continue
            </Button>
          </Form>}

        {hasExaptiveProvider && hasNonExaptiveProvider &&
        <div
          className="mt-6 flex w-full items-center"
          data-testid="landing-or-divider">

            <span className="h-px flex-1 bg-gray-300" />
            <span className="ml-4 mr-4 text-gray-400">OR</span>
            <span className="h-px flex-1 bg-gray-300" />
          </div>}

        {hasNonExaptiveProvider &&
        <AuthProviders
          inviteCode={inviteCode}
          nonExaptiveProviders={nonExaptiveProviders}
          autoLogin={
          autoLogin === 'true' && nonExaptiveProviders.length === 1} />}



      </AuthPanel>
    </>);

}

export default function LandingWrapper(): JSX.Element {
  return (
    <CityContext.Consumer>
      {(cityContext) => {
        const authProviders = cityContext.cityConfig?.authProviders ?? [];
        return (
          <Landing
            hasExaptiveProvider={authProviderUtils.hasExaptiveProvider(
              authProviders
            )}
            nonExaptiveProviders={authProviderUtils.nonExaptiveProviders(
              authProviders
            )}
            hasNonExaptiveProvider={authProviderUtils.hasNonExaptiveProvider(
              authProviders
            )} />);


      }}
    </CityContext.Consumer>);

}